<template>
  <div>
    <div class="modal_design">
      <div class="modal_wrap">
        <div class="header">
          <span><img src="/img/onboarding/powered_by_stripe.svg" /></span>
          <span
            ><img
              src="/img/onboarding/cancel.svg"
              @click="$emit('close')"
              style="float: right; cursor: pointer"
          /></span>
        </div>
        <div class="info_div">
          <div class="logo_div">
            <img src="/img/onboarding/grupa_logo.svg" />
          </div>
          <div class="client_info" v-if="modalType == 'design_sprint'">
            <!-- <div class="customer_email">{{user.email}}</div> -->
            <div class="label_amount">
              <div class="label_left">Amount Due:</div>
              <div class="label_right">
                ${{ priceRoundUp(amount).price
                }}{{ priceRoundUp(amount).decimal }}
              </div>
            </div>
            <div class="label_amount">
              <div class="label_left">VAT:</div>
              <div class="label_right">
                ${{ vatCharges(amount).price }}{{ vatCharges(amount).decimal }}
              </div>
            </div>
            <div class="label_amount">
              <div class="label_left">Transaction Fee:</div>
              <div class="label_right">
                ${{ transactionFee(amount).price
                }}{{ transactionFee(amount).decimal }}
              </div>
            </div>
            <div class="label_amount">
              <div class="label_left" style="color: #000000">Total:</div>
              <div class="label_right" style="color: #000000">
                ${{ amountDue(amount).price }}
              </div>
            </div>
          </div>
        </div>
        <div class="text_input_div" v-show="hasCreditCard == false">
          <div class="input_label label11">
            Card number<span
              id="card_number_error"
              class="error_message"
              style="float: right"
            ></span>
          </div>
          <!-- <div class="pass-field"><input type="text" v-model="form.card_number"  class="form-control"><img :src="'/img/credit-card/'+defaultcard+'.png'" height="48px"/></div> -->
          <div class="pass-field">
            <div id="card_number" class="card_number form-control"></div>
            <img
              :src="'/img/credit-card/' + defaultcard + '.png'"
              style="height: 24px; width: 24px"
            />
          </div>
          <div class="div_flex">
            <div class="valid_date" style="margin-right: 24px">
              <div class="input_label">Valid till</div>
              <div id="expiry_date" class="expiry_date form-control"></div>
            </div>
            <div class="valid_date">
              <div class="input_label">
                CVV
                <img
                  style="float: right"
                  src="/img/credit-card/question-mark.png"
                />
              </div>
              <div id="cvv" class="cvv form-control"></div>
            </div>
          </div>
          <div
            id="expiry_date_error"
            style="height: 22px; margin-top: 13px"
            class="error_message"
          ></div>
          <div class="div_btn">
            <button
              @click="doValidation"
              :class="disabled == true ? 'grupa-disabled-btn' : null"
              :disabled="disabled"
              class="payment_btn grupa-blue-btn"
            >
              <img
                src="/img/onboarding/grey_lock.svg"
                style="margin-right: 16px"
              />
              <span v-if="modalType == 'design_sprint'"
                >{{ btnText }} ${{ amountDue(amount).price }}</span
              >
              <span v-else>Save To Stripe</span>
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
                v-show="spinner"
              />
            </button>
          </div>
        </div>
        <div v-show="hasCreditCard == true" class="select_credit_card">
          <div class="section_header">Select card to complete payment.</div>
          <div
            class="credit_card_div"
            v-for="(card, k) in userCreditCard"
            :key="k"
          >
            <div class="left_info">
              <div v-if="card.card_brand">
                <img
                  v-if="card.card_brand.toUpperCase() == 'MASTERCARD'"
                  src="/img/mastercard.svg"
                />
                <img v-else src="/img/visa.svg" />
              </div>
              <div v-else>
                <img src="/img/visa.svg" />
              </div>
              <div class="div_card_digit">XXXX {{ card.last4 }}</div>
            </div>
            <div class="right_info">
              <div
                @click="
                  defaultCardId = card.id;
                  cardIssuingCountry = card.issuing_country;
                "
              >
                <img
                  v-if="card.id == defaultCardId"
                  src="/img/credit-card/check.svg"
                  style="float: right"
                />
                <img
                  v-else
                  src="/img/credit-card/uncheck.svg"
                  style="float: right"
                />
              </div>
            </div>
          </div>
          <!-- 
 v-if="!hasCreditCard"

           -->
          <div
            class="use_card_div"
           
            @click="hasCreditCard = false"
          >
            Use a different card
          </div>
          <div style="margin-top: 40px">
            <button
              :class="disabled == true ? 'grupa-disabled-btn' : null"
              @click="handleCardAutoCharge"
              :disabled="disabled ? disabled : amount < 1 ? true : false"
              class="payment_btn grupa-blue-btn"
            >
              <img
                src="/img/onboarding/grey_lock.svg"
                style="margin-right: 16px"
              />
              <span>{{ btnText }} ${{ amountDue(amount).price }}</span>
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
                v-show="spinner"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <AlertSuccess v-if="successMsg" time="7" />
    <AlertError v-if="errorMsg" time="7" />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import mixPanel from "@/mixins/mixPanel";
import AlertSuccess from "@/components/alertSuccess";
import AlertError from "@/components/alertError";
import { stripePayment, savePaymentCard, debitCardAutoCharge } from "@/api";
export default {
  mixins: [mixPanel],
  components: {
    AlertSuccess,
    AlertError,
  },
  props: [
    "amount",
    "user",
    "modalType",
    "paymentInfo",
    "openSuccess",
    "userCreditCard",
    "isWorkSpace",
  ],
  data: () => ({
    publishableKey: process.env.VUE_APP_STRIPE_PUB_KEY,
    form: {
      card_number: "",
    },
    defaultcard: "credit",
    cardType: {},
    stripe: Stripe(`${process.env.VUE_APP_STRIPE_PUB_KEY}`),
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
    cardNumberError: "required",
    expiryDateError: "required",
    cvvError: "required",
    spinner: false,
    isSubmitted: false,
    disabled: false,
    btnText: "Pay",
    hasCreditCard: false,
    defaultCardId: null,
    cardIssuingCountry: null,
  }),

  mounted() {
    this.createStripeBox();
    if (this.userCreditCard.length > 0) {
      let defaultCard = this.userCreditCard.find(
        (card) => card.default_card == true
      );
      this.defaultCardId = defaultCard
        ? defaultCard.id
        : this.userCreditCard[0].id;
      this.cardIssuingCountry = defaultCard
        ? defaultCard.issuing_country
        : this.userCreditCard[0].issuing_country;
      this.hasCreditCard = true;
    } else {
      this.hasCreditCard = false;
    }
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    ...mapActions("modalMgmt", ["updatePaymentModalFn"]),
    ...mapActions("clientDashboard", ["updateProject"]),

    createStripeBox() {
      const stripe = this.stripe;
      const style = {
        base: {
          color: "#303238",
          fontSize: "16px",
          color: "#32325d",
          fontSmoothing: "antialiased",
          "::placeholder": {
            color: "#ccc",
          },
        },
        invalid: {
          color: "#e5424d",
          ":focus": {
            color: "#303238",
          },
        },
      };
      let elements = stripe.elements();
      // Create card details
      this.cardNumber = elements.create("cardNumber");
      this.cardExpiry = elements.create("cardExpiry", { style });
      this.cardCvc = elements.create("cardCvc", { style });

      // Mount the card to the dom
      this.cardNumber.mount("#card_number");
      this.cardExpiry.mount("#expiry_date");
      this.cardCvc.mount("#cvv");
      // Setup event listeners for change event
      this.cardNumber.addEventListener("change", (event) => {
        if (event.brand !== "unknown") {
          this.defaultcard = event.brand;
        } else {
          this.defaultcard = "credit";
        }
        var displayError = document.getElementById("card_number_error");
        if (event.error) {
          displayError.textContent = event.error.message;
          this.cardNumberError = event.error.message;
        } else {
          displayError.textContent = "";
          this.cardNumberError = "";
        }
        if (event.complete) {
          this.cardExpiry.focus();
        }
      });
      this.cardExpiry.addEventListener("change", (event) => {
        var displayError = document.getElementById("expiry_date_error");
        if (event.error) {
          displayError.textContent = event.error.message;
          this.expiryDateError = event.error.message;
        } else {
          displayError.textContent = "";

          this.expiryDateError = "";
        }
        if (event.complete) {
          this.cardCvc.focus();
        }
      });
      this.cardCvc.addEventListener("change", (event) => {
        var displayError = document.getElementById("expiry_date_error");
        if (event.error) {
          displayError.textContent = event.error.message;
          this.cvvError = event.error.message;
        } else {
          displayError.textContent = "";
          this.cvvError = "";
        }
      });
    },
    doValidation() {
      if (
        this.cardNumberError === "" &&
        this.expiryDateError === "" &&
        this.cvvError === ""
      ) {
        this.$developConsole(this.cardNumberError, this.expiryDateError);
        this.spinner = true;
        this.isSubmitted = true;
        this.submitForm();
      }
    },
    async submitForm() {
      this.disabled = true;
      this.btnText = "Processing";
      const cardDetails = {
        cardNumber: document.getElementById("card_number"),
        cardExpiry: document.getElementById("expiry_date"),
        cvv: document.getElementById("cvv"),
      };
      await this.stripe.createToken(this.cardNumber).then((result) => {
        if (result.error) {
          // Inform the customer that there was an error.
          var errorElement = document.getElementById("card_number_error");
          errorElement.textContent = result.error.message;
        } else if (this.modalType == "design_sprint") {
          let paymentPlan = "sprint";
          let cardUnique = this.isCardUnique(result.token.card);
          if (cardUnique) {
            const data = new FormData();
            const project_id = parseInt(this.$route.params.id, 10);
            data.append("project_id", project_id);
            data.append("token", result.token.id);
            data.append("email", this.user.email);
            data.append("purpose", this.paymentInfo.purpose);
            data.append("iteration_id", this.paymentInfo.iteration_id);
            data.append("payment_plan", this.paymentInfo.payment_plan);
            data.append("amount", this.amount);

            
            this.handleStripePayment(data);
          } else {
            this.handleCardAutoCharge();
          }
        } else if (this.modalType == "add_card") {
          const dataFrom = new FormData();
          let cardUnique = this.isCardUnique(result.token.card);
          if (cardUnique) {
            dataFrom.append("token", result.token.id);
            dataFrom.append("email", this.user.email);
            savePaymentCard(dataFrom).then((response) => {
              this.$developConsole(response.data, "card added");
              if (response.data.status == 1) {
                this.spinner = false;
                this.isSubmitted = false;
                this.$emit("saveCard", response.data.stripe);
              }
            });
          } else {
            this.spinner = false;
            this.isSubmitted = false;
            this.setErrorMsg(
              "This card is already added to your account please try another card."
            );
          }
        }
      });
    },
    handleStripePayment(data) {
      stripePayment(data)
        .then((response) => {
          this.disabled = false;
          this.btnText = "Pay";
          this.spinner = false;
          if (response.data.status == 1) {
            this.$developConsole(response.data, "Payment Done !");
            let projectId = this.$route.params.id;
            // if (!this.isWorkSpace) {
            //   this.$router.push({
            //     name: "project_workspace",
            //     params: { id: projectId },
            //   });
            // }
            this.updateProject(response.data.project);
            this.updatePaymentModalFn(true);
            this.$emit("close");
            this.mixPanelTrack({
              event: "PaymentSuccessful",
            });
          } else {
            this.setErrorMsg("Transaction Failed");
            this.mixPanelTrack({
              event: "PaymentFailed",
            });
          }
        })
        .catch((error) => {
          this.disabled = false;
          this.btnText = "Pay";
          this.spinner = false;
          this.setErrorMsg("Transaction Failed");
          this.$developConsole(error.response, "Payment Error !");
          this.mixPanelTrack({
            event: "PaymentFailed",
          });
        });
    },
    priceRoundUp(data) {
      let price = data.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return {
        price: price.substring(0, price.length - 2),
        decimal: price.substring(price.length - 2, price.length),
      };
    },
    transactionFee(amount) {
      let amountToPay = amount;
      let percent = amount * 0.075;
      let amountToPay1 = percent + amountToPay;
      let tranFee = (0.03 * amountToPay1).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return {
        price: tranFee.substring(0, tranFee.length - 2),
        decimal: tranFee.substring(tranFee.length - 2, tranFee.length),
      };
    },
    vatCharges(amount) {
      let amountToPay = amount * 0.075;
      let tranFee = amountToPay.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return {
        price: tranFee.substring(0, tranFee.length - 2),
        decimal: tranFee.substring(tranFee.length - 2, tranFee.length),
      };
    },
    amountDue(amount) {
      let amountDue = amount;
      let transactionFeePercent = amount * 0.075;
      let transactionFee = transactionFeePercent + amount;
      let totalTran = transactionFee * 0.03;
      let vatCharges = transactionFeePercent;
      let totalDue = amountDue + totalTran + vatCharges;
      let tPrice = totalDue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return {
        price: tPrice,
      };
    },
    isCardUnique(cardDetails) {
      let filterCard = this.userCreditCard.filter(
        (card) =>
          card.last4 == cardDetails.last4 &&
          card.card_brand == cardDetails.brand
      );
      if (filterCard.length == 0) {
        return true;
      } else {
        this.defaultCardId = filterCard[0].id;
        return false;
      }
    },
    handleCardAutoCharge() {
      const project_id = this.$route.params.id;
      this.spinner = true;
      this.isSubmitted = true;
      this.disabled = true;
      this.btnText = "Processing";
      let payload = {
        payment_plan: this.paymentInfo.payment_plan,
        iteration_id: this.paymentInfo.iteration_id,
        active_card_id: this.defaultCardId,
        purpose: this.paymentInfo.purpose,
        project_id,
      };
      debitCardAutoCharge(payload)
        .then((response) => {
          this.disabled = false;
          this.btnText = "Pay";
          this.spinner = false;
          if (response.data.status == 1) {
            let projectId = this.$route.params.id;
            // if (!this.isWorkSpace) {
            //   this.$router.push({
            //     name: "project_workspace",
            //     params: { id: projectId },
            //   });
            // }
            this.updateProject(response.data.project);
            this.updatePaymentModalFn(true);
            this.$emit("close");
            this.mixPanelTrack({
              event: "PaymentSuccessful",
            });
          } else {
            this.setErrorMsg("Please try again");
            this.mixPanelTrack({
              event: "PaymentFailed",
            });
          }
        })
        .catch((error) => {
          this.setErrorMsg("Please try again");
          this.disabled = false;
          this.btnText = "Pay";
          this.spinner = false;
          this.mixPanelTrack({
            event: "PaymentFailed",
          });
        });
    },
  },
  computed: {
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
  },
};
</script>
<style scoped>
.modal_design {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal_wrap {
  background: #ffffff;
  flex-direction: column;
  width: 440px;
  min-height: 50vh;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.header {
  text-align: initial;
  background: #f7f7f7;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0px 0px;
  padding: 24px;
}
.info_div {
  display: flex;
  padding: 48px 24px;
  border-bottom: solid 0.5px #e4e5e7;
}
.logo_div {
  width: 30%;
  text-align: left;
}
.client_info {
  width: 70%;
  text-align: right;
}
.customer_email {
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #979da5;
}
.amount {
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  padding-top: 13px;
}
.label_amount {
  display: flex;
  padding-top: 13px;
}
.label_left,
.label_right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.amount span {
  font-weight: 500;
  font-size: 32px;
}
.text_input_div {
  padding: 48px 24px;
}
.input_label {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  text-align: left;
}
.div_flex {
  display: flex;
  margin-top: 24px;
}
.valid_date {
  width: 50%;
}
.div_btn {
  margin-top: 13px;
}
.payment_btn {
  width: 100%;
}

.stripe-card {
  background: #ffffff;
  border: 0.5px solid #979da5;
  box-sizing: border-box;
}

/*  */
.CardField-input-wrapper .CardField-cvc,
.CardField-input-wrapper .CardField-expiry {
  display: none !important;
}
.form-control {
  font-size: 14px;
  background: #ffffff;
  border: 0.5px solid #e4e5e7;
  box-sizing: border-box;
  margin-top: 8px;
  margin-bottom: 0px;
}
.pass-field img {
  right: 3px;
  top: 24%;
}
.pass-field {
  position: relative;
  height: 50px;
}
.pass-field img {
  position: absolute;
  top: 30%;
  right: 12px;
}
.error_message {
  font-weight: 100;
  font-size: 12px;
  color: red;
}
.card_number,
.expiry_date,
.cvv {
  padding-top: 16px;
}

.select_credit_card {
  padding: 48px 24px;
}
.section_header {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  align-items: center;
  color: #15171a;
  text-align: left;
}
.credit_card_div {
  display: flex;
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 18px 16px;
  text-align: initial;
}
.div_card_digit {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-left: 16px;
  padding-top: 5px;
}
.left_info {
  width: 40%;
  display: flex;
}
.right_info {
  width: 60%;
  justify-content: flex-end;
  display: flex;
}
.use_card_div {
  margin-top: 24px;
  font-family: Moderat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #0055a5;
  text-align: left;
  cursor: pointer;
}
</style>
