var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "modal_design" }, [
        _c("div", { staticClass: "modal_wrap" }, [
          _c("div", { staticClass: "header" }, [
            _vm._m(0),
            _c("span", [
              _c("img", {
                staticStyle: { float: "right", cursor: "pointer" },
                attrs: { src: "/img/onboarding/cancel.svg" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "info_div" }, [
            _vm._m(1),
            _vm.modalType == "design_sprint"
              ? _c("div", { staticClass: "client_info" }, [
                  _c("div", { staticClass: "label_amount" }, [
                    _c("div", { staticClass: "label_left" }, [
                      _vm._v("Amount Due:"),
                    ]),
                    _c("div", { staticClass: "label_right" }, [
                      _vm._v(
                        "\n               $" +
                          _vm._s(_vm.priceRoundUp(_vm.amount).price) +
                          _vm._s(_vm.priceRoundUp(_vm.amount).decimal) +
                          "\n             "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "label_amount" }, [
                    _c("div", { staticClass: "label_left" }, [_vm._v("VAT:")]),
                    _c("div", { staticClass: "label_right" }, [
                      _vm._v(
                        "\n               $" +
                          _vm._s(_vm.vatCharges(_vm.amount).price) +
                          _vm._s(_vm.vatCharges(_vm.amount).decimal) +
                          "\n             "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "label_amount" }, [
                    _c("div", { staticClass: "label_left" }, [
                      _vm._v("Transaction Fee:"),
                    ]),
                    _c("div", { staticClass: "label_right" }, [
                      _vm._v(
                        "\n               $" +
                          _vm._s(_vm.transactionFee(_vm.amount).price) +
                          _vm._s(_vm.transactionFee(_vm.amount).decimal) +
                          "\n             "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "label_amount" }, [
                    _c(
                      "div",
                      {
                        staticClass: "label_left",
                        staticStyle: { color: "#000000" },
                      },
                      [_vm._v("Total:")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "label_right",
                        staticStyle: { color: "#000000" },
                      },
                      [
                        _vm._v(
                          "\n               $" +
                            _vm._s(_vm.amountDue(_vm.amount).price) +
                            "\n             "
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasCreditCard == false,
                  expression: "hasCreditCard == false",
                },
              ],
              staticClass: "text_input_div",
            },
            [
              _vm._m(2),
              _c("div", { staticClass: "pass-field" }, [
                _c("div", {
                  staticClass: "card_number form-control",
                  attrs: { id: "card_number" },
                }),
                _c("img", {
                  staticStyle: { height: "24px", width: "24px" },
                  attrs: {
                    src: "/img/credit-card/" + _vm.defaultcard + ".png",
                  },
                }),
              ]),
              _vm._m(3),
              _c("div", {
                staticClass: "error_message",
                staticStyle: { height: "22px", "margin-top": "13px" },
                attrs: { id: "expiry_date_error" },
              }),
              _c("div", { staticClass: "div_btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "payment_btn grupa-blue-btn",
                    class: _vm.disabled == true ? "grupa-disabled-btn" : null,
                    attrs: { disabled: _vm.disabled },
                    on: { click: _vm.doValidation },
                  },
                  [
                    _c("img", {
                      staticStyle: { "margin-right": "16px" },
                      attrs: { src: "/img/onboarding/grey_lock.svg" },
                    }),
                    _vm.modalType == "design_sprint"
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.btnText) +
                              " $" +
                              _vm._s(_vm.amountDue(_vm.amount).price)
                          ),
                        ])
                      : _c("span", [_vm._v("Save To Stripe")]),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.spinner,
                          expression: "spinner",
                        },
                      ],
                      staticClass: "button-spinner",
                      staticStyle: { "margin-left": "20px" },
                      attrs: { src: "/img/lightbox/preloader.gif" },
                    }),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasCreditCard == true,
                  expression: "hasCreditCard == true",
                },
              ],
              staticClass: "select_credit_card",
            },
            [
              _c("div", { staticClass: "section_header" }, [
                _vm._v("Select card to complete payment."),
              ]),
              _vm._l(_vm.userCreditCard, function (card, k) {
                return _c("div", { key: k, staticClass: "credit_card_div" }, [
                  _c("div", { staticClass: "left_info" }, [
                    card.card_brand
                      ? _c("div", [
                          card.card_brand.toUpperCase() == "MASTERCARD"
                            ? _c("img", {
                                attrs: { src: "/img/mastercard.svg" },
                              })
                            : _c("img", { attrs: { src: "/img/visa.svg" } }),
                        ])
                      : _c("div", [
                          _c("img", { attrs: { src: "/img/visa.svg" } }),
                        ]),
                    _c("div", { staticClass: "div_card_digit" }, [
                      _vm._v("XXXX " + _vm._s(card.last4)),
                    ]),
                  ]),
                  _c("div", { staticClass: "right_info" }, [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            _vm.defaultCardId = card.id
                            _vm.cardIssuingCountry = card.issuing_country
                          },
                        },
                      },
                      [
                        card.id == _vm.defaultCardId
                          ? _c("img", {
                              staticStyle: { float: "right" },
                              attrs: { src: "/img/credit-card/check.svg" },
                            })
                          : _c("img", {
                              staticStyle: { float: "right" },
                              attrs: { src: "/img/credit-card/uncheck.svg" },
                            }),
                      ]
                    ),
                  ]),
                ])
              }),
              _c(
                "div",
                {
                  staticClass: "use_card_div",
                  on: {
                    click: function ($event) {
                      _vm.hasCreditCard = false
                    },
                  },
                },
                [_vm._v("\n           Use a different card\n         ")]
              ),
              _c("div", { staticStyle: { "margin-top": "40px" } }, [
                _c(
                  "button",
                  {
                    staticClass: "payment_btn grupa-blue-btn",
                    class: _vm.disabled == true ? "grupa-disabled-btn" : null,
                    attrs: {
                      disabled: _vm.disabled
                        ? _vm.disabled
                        : _vm.amount < 1
                        ? true
                        : false,
                    },
                    on: { click: _vm.handleCardAutoCharge },
                  },
                  [
                    _c("img", {
                      staticStyle: { "margin-right": "16px" },
                      attrs: { src: "/img/onboarding/grey_lock.svg" },
                    }),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.btnText) +
                          " $" +
                          _vm._s(_vm.amountDue(_vm.amount).price)
                      ),
                    ]),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.spinner,
                          expression: "spinner",
                        },
                      ],
                      staticClass: "button-spinner",
                      staticStyle: { "margin-left": "20px" },
                      attrs: { src: "/img/lightbox/preloader.gif" },
                    }),
                  ]
                ),
              ]),
            ],
            2
          ),
        ]),
      ]),
      _vm.successMsg ? _c("AlertSuccess", { attrs: { time: "7" } }) : _vm._e(),
      _vm.errorMsg ? _c("AlertError", { attrs: { time: "7" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", { attrs: { src: "/img/onboarding/powered_by_stripe.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo_div" }, [
      _c("img", { attrs: { src: "/img/onboarding/grupa_logo.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input_label label11" }, [
      _vm._v("\n           Card number"),
      _c("span", {
        staticClass: "error_message",
        staticStyle: { float: "right" },
        attrs: { id: "card_number_error" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "div_flex" }, [
      _c(
        "div",
        { staticClass: "valid_date", staticStyle: { "margin-right": "24px" } },
        [
          _c("div", { staticClass: "input_label" }, [_vm._v("Valid till")]),
          _c("div", {
            staticClass: "expiry_date form-control",
            attrs: { id: "expiry_date" },
          }),
        ]
      ),
      _c("div", { staticClass: "valid_date" }, [
        _c("div", { staticClass: "input_label" }, [
          _vm._v("\n               CVV\n               "),
          _c("img", {
            staticStyle: { float: "right" },
            attrs: { src: "/img/credit-card/question-mark.png" },
          }),
        ]),
        _c("div", { staticClass: "cvv form-control", attrs: { id: "cvv" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }